import { useRef, useEffect, useState } from "react";
import {
  Color,
  Vector3,
  // SphereBufferGeometry,
  SphereGeometry,
  RawShaderMaterial,
  Mesh,
  Scene,
  OrthographicCamera,
  WebGLRenderer,
} from "three";
import { frag, vert } from "./blobShaders";

/**
 * Helper Functions
 */

function randomInteger(min, max){
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomNum(min, max) {
  return Math.random() * (max - min) + min;
}

function getRandomColor() {
  const colors = [0xf4642b, 0x2f3192, 0x492ae6, 0xeeeeee];
  // const colors = [0x5782E6, 0x83CF83, 0xE3725D, 0xeeeeee, 0xFAEFF, 0xF6A85B];
  let color = new Color(0xffffff);
  color.setHex(colors[Math.floor(Math.random() * colors.length)]);
  return color;
}

var blobs = [];
var sphereNum ;
var gravity = new Vector3(0, 0, 0);
var boundPos;

var options = {
  roundRadiusFactor: 1,
  collisionFactor: 1,
  opacity: 1,
};

/**
 * Single Blob
 */

function Blob(width, height) {
  this.isInteract = false;
  this.touchstartPos = null;
  this.backupPos = null;
  this.width = width;
  this.height = height;

  this.init = function (width, height) {
    let avergeRadius = Math.sqrt(((width + 20) * height) / sphereNum / Math.PI);
    this.baseRadius = getRandomNum(avergeRadius / 2, (avergeRadius * 3) / 2);
    this.radius = this.baseRadius;
    this.speed = getRandomNum(0.3, 1);
    this.offset = getRandomNum(0, Math.PI * 2);
    this.amp = getRandomNum(0, this.baseRadius * 0.3);
    this.position = new Vector3(
      getRandomNum(boundPos.left + this.radius, boundPos.right - this.radius),
      getRandomNum(boundPos.bottom + this.radius, boundPos.top - this.radius),
      0
    );
    this.vel = new Vector3();
    this.geometry = new SphereGeometry(1, 64, 64); //new SphereBufferGeometry(1, 64, 64);
    this.uniforms = {
      time: { type: "f", value: 0 },
      lightPosition: { type: "v3", value: new Vector3(-10000, 200, 400) },
      cameraPos: { type: "v3", value: new Vector3(0, 0, 800) },

      pos: { type: "v3", value: this.position },
      radius: { type: "f", value: this.radius },

      bgType: { type: "f", value: 1.0 },
      bgColor1: { type: "v3", value: new Vector3() },
      bgColor2: { type: "v3", value: new Vector3() },

      cutPoints: { type: "v3v", value: [] },
      cutNormals: { type: "v3v", value: [] },
      cutRange: { type: "fv", value: [] },

      roundRadiusFactor: { type: "f", value: 0.0 },
      collisionFactor: { type: "f", value: 0.0 },
      opacity: { type: "f", value: 0.5 },
      frosted: { type: "f", value: 0.0 },
    };
    this.material = new RawShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: vert,
      fragmentShader: frag,
      transparent: true,
    });
    this.mesh = new Mesh(this.geometry, this.material);

    this.setBackgroundColor();
  };

  this.setBackgroundColor = function () {
    let rand = Math.random();
    if (rand < 0.33) {
      this.uniforms.bgType.value = 0.0;
      let c1 = getRandomColor();
      this.uniforms.bgColor1.value = new Vector3(c1.r, c1.g, c1.b);
      this.uniforms.bgColor2.value = new Vector3(c1.r, c1.g, c1.b);
    } else if (rand < 0.66) {
      this.uniforms.bgType.value = 1.0;
      let c1 = getRandomColor();
      let c2 = getRandomColor();
      this.uniforms.bgColor1.value = new Vector3(c1.r, c1.g, c1.b);
      this.uniforms.bgColor2.value = new Vector3(c2.r, c2.g, c2.b);
    } else {
      this.uniforms.bgType.value = 2.0;
      let c1 = getRandomColor();
      let c2 = getRandomColor();
      this.uniforms.bgColor1.value = new Vector3(c1.r, c1.g, c1.b);
      this.uniforms.bgColor2.value = new Vector3(c2.r, c2.g, c2.b);
    }
  };

  this.updatePosition = function () {
    if (this.isInteract) return;
    let force = new Vector3().add(gravity);
    for (let i = 0; i < blobs.length; i++) {
      if (blobs[i] != this) {
        let other = blobs[i];
        let between = this.position.clone().sub(other.position);
        let dist = between.length();
        let touchDist = this.radius + other.radius;

        if (dist < touchDist) {
          force.add(
            between.normalize().multiplyScalar((touchDist - dist) * 0.05)
          );
        }
      }
    }
    let timeDelta = 1;
    let decay = 0.9;

    this.vel.add(force.multiplyScalar(timeDelta));

    this.vel.multiplyScalar(decay);

    this.vel.multiplyScalar(Math.min(1.0, 50.0 / this.vel.length()));

    this.position.add(this.vel.multiplyScalar(timeDelta));

    this.position.x = this.constrain(
      this.position.x,
      boundPos.left,
      boundPos.right
    );
    this.position.y = this.constrain(
      this.position.y,
      boundPos.bottom,
      boundPos.top
    );
    this.position.z = 0;
  };

  this.constrain = function (v, min, max) {
    return Math.max(min + this.radius, Math.min(max - this.radius, v));
  };

  this.updateUniforms = function (time) {
    this.uniforms.time.value = time;
    this.uniforms.pos.value = this.position;
    this.uniforms.radius.value = this.radius;

    this.uniforms.cutPoints.value = [];
    this.uniforms.cutNormals.value = [];
    this.uniforms.cutRange.value = [];

    this.uniforms.roundRadiusFactor.value = options.roundRadiusFactor;
    this.uniforms.collisionFactor.value = options.collisionFactor;
    this.uniforms.opacity.value = options.opacity;
    this.uniforms.frosted.value = options.frosted ? 1.0 : 0.0;

    for (let i = 0; i < blobs.length; i++) {
      if (blobs[i] != this) {
        let other = blobs[i];
        let between = other.position.clone().sub(this.position);
        let dist = between.length();

        if (dist < this.radius + other.radius) {
          let angleA = Math.acos(
            (other.radius * other.radius -
              this.radius * this.radius -
              dist * dist) /
              (-2 * this.radius * dist)
          );
          let angleT = Math.atan2(between.y, between.x);
          let angle1 = angleT + angleA;
          let angle2 = angleT - angleA;

          let p1 = new Vector3(Math.cos(angle1), Math.sin(angle1), 0)
            .multiplyScalar(this.radius)
            .add(this.position);
          let p2 = new Vector3(Math.cos(angle2), Math.sin(angle2), 0)
            .multiplyScalar(this.radius)
            .add(this.position);

          this.uniforms.cutPoints.value.push(
            p1.clone().add(p2).multiplyScalar(0.5)
          );
          this.uniforms.cutNormals.value.push(
            between.clone().multiplyScalar(-1).normalize()
          );
          this.uniforms.cutRange.value.push(p1.clone().sub(p2).length() * 0.5);
        }
      }
    }

    for (let i = this.uniforms.cutPoints.value.length; i < 47; i++) {
      this.uniforms.cutPoints.value.push(new Vector3(999, 999, 0));
      this.uniforms.cutNormals.value.push(new Vector3(999, 999, 0));
      this.uniforms.cutRange.value.push(0);
    }
  };
  this.updateRadius = function (time) {
    if (time) {
      this.radius =
        this.baseRadius +
        Math.sin((time / 1000.0) * this.speed + this.offset) *
          this.baseRadius *
          0.1;
      this.radius = Math.max(20, this.radius);
    }
  };
  this.touchstart = function (e) {
    var rect = e.target.getBoundingClientRect();
    var x = e.targetTouches[0].pageX - rect.left;
    var y = e.targetTouches[0].pageY - rect.top;
    var mapPos = this.mapCroods(x, y);
    if (mapPos.distanceTo(this.position) < this.radius) {
      this.isInteract = true;
      this.touchstartPos = this.mapCroods(x, y);
      this.backupPos = this.position.clone();
    }
    return this.isInteract;
  };
  this.touchmove = function (e) {
    if (this.isInteract) {
      let rect = e.target.getBoundingClientRect();
      let x = e.targetTouches[0].pageX - rect.left;
      let y = e.targetTouches[0].pageY - rect.top;
      let pos = this.mapCroods(x, y);
      this.position = this.backupPos.clone().add(pos.sub(this.touchstartPos));
    }
  };
  this.touchend = function (e) {
    this.isInteract = false;
  };
  this.mousedown = function (e) {
    let x = e.offsetX;
    let y = e.offsetY;
    var mapPos = this.mapCroods(x, y);
    if (mapPos.distanceTo(this.position) < this.radius) {
      this.isInteract = true;
      this.touchstartPos = this.mapCroods(x, y);
      this.backupPos = this.position.clone();
    }
    return this.isInteract;
  };
  this.mousemove = function (e) {
    if (this.isInteract) {
      let x = e.offsetX;
      let y = e.offsetY;
      let pos = this.mapCroods(x, y);
      this.position = this.backupPos.clone().add(pos.sub(this.touchstartPos));
    }
  };
  this.mouseup = function (e) {
    this.isInteract = false;
  };
  this.mapCroods = function (x, y) {
    return new Vector3(
      x - this.width / 2,
      -y + this.height / 2,
      this.position.z
    );
  };
  this.iMapCroods = function (x, y) {
    return new Vector2(x + this.width / 2, -y + this.height / 2);
  };
  this.anim = function (time) {
    this.updateRadius(time);
    this.updatePosition(time);
    this.updateUniforms(time);
  };
  this.init(width, height);
}

/**
 * Blob Scene
 */
const Blobs = ({sphereMin = 3, sphereMax = 5}) => {
  sphereNum = randomInteger(sphereMin, sphereMax);
  const mount = useRef(null);
  const controls = useRef(null);
  const [isAnimating, setAnimating] = useState(true);

  useEffect(() => {
    /**
     * Set up Scene
     */
    let width = mount.current.clientWidth;
    let height = mount.current.clientHeight;
    let frameId;

    boundPos = {
      left: -width / 2,
      right: width / 2,
      top: height / 2,
      bottom: -height / 2,
    };

    const scene = new Scene();

    const camera = new OrthographicCamera(
      mount.current.clientWidth / -2,
      mount.current.clientWidth / 2,
      mount.current.clientHeight / 2,
      mount.current.clientHeight / -2,
      1,
      100000
    );
    camera.lookAt(new Vector3());
    camera.position.set(0, 0, 800);

    const renderer = new WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);

    /**
     * Add Blobs
     */
    blobs = [];
    for (let i = 0; i < sphereNum; i++) {
      let sp = new Blob(width, height);
      blobs.push(sp);
      scene.add(sp.mesh);
    }

    /**
     * Basic Render Loop
     */

    const renderScene = (time) => {
      for (let i = 0; i < blobs.length; i++) {
        blobs[i].anim(time);
      }
      renderer.render(scene, camera);
    };

    const handleResize = () => {
      width = mount.current.clientWidth;
      height = mount.current.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderScene();
    };

    const animate = (time) => {
      renderScene(time);
      frameId = window.requestAnimationFrame(animate);
    };

    const start = () => {
      if (!frameId) {
        frameId = requestAnimationFrame(animate);
      }
    };

    const stop = () => {
      cancelAnimationFrame(frameId);
      frameId = null;
    };

    mount.current.appendChild(renderer.domElement);
    handleResize();

    /**
     * Event Listeners
     */
    window.addEventListener("resize", handleResize);
    const initTouchInteract = function () {
      let canvas = mount.current;
      canvas.addEventListener(
        "touchstart",
        function (e) {
          touchstart(e);
        }.bind(this),
        { passive: true }
      );
      canvas.addEventListener(
        "touchmove",
        function (e) {
          touchmove(e);
        }.bind(this),
        { passive: true }
      );
      canvas.addEventListener(
        "touchend",
        function (e) {
          touchend(e);
        }.bind(this),
        { passive: true }
      );
      canvas.addEventListener(
        "mousedown",
        function (e) {
          mousedown(e);
        }.bind(this),
        { passive: true }
      );
      canvas.addEventListener(
        "mousemove",
        function (e) {
          mousemove(e);
        }.bind(this),
        { passive: true }
      );
      canvas.addEventListener(
        "mouseup",
        function (e) {
          mouseup(e);
        }.bind(this),
        { passive: true }
      );
    };
    const touchstart = function (e) {
      for (let i = 0; i < blobs.length; i++) {
        if (blobs[i].touchstart(e)) break;
      }
    };
    const touchmove = function (e) {
      for (let i = 0; i < blobs.length; i++) {
        blobs[i].touchmove(e);
      }
    };
    const touchend = function (e) {
      for (let i = 0; i < blobs.length; i++) {
        blobs[i].touchend(e);
      }
    };
    const mousedown = function (e) {
      for (let i = 0; i < blobs.length; i++) {
        if (blobs[i].mousedown(e)) break;
      }
    };
    const mousemove = function (e) {
      for (let i = 0; i < blobs.length; i++) {
        blobs[i].mousemove(e);
      }
    };
    const mouseup = function (e) {
      for (let i = 0; i < blobs.length; i++) {
        blobs[i].mouseup(e);
      }
    };

    initTouchInteract();
    start();

    controls.current = { start, stop };

    return () => {
      stop();
      window.removeEventListener("resize", handleResize);
      mount.current.removeChild(renderer.domElement);

      for (let i = 0; i < blobs.length; i++) {
        blobs[i].geometry.dispose();
        blobs[i].material.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (isAnimating) {
      controls.current.start();
    } else {
      controls.current.stop();
    }
  }, [isAnimating]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "#DCDCDC",
      }}
      ref={mount}
    />
  );
};

export default Blobs;
