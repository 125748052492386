import { baseSize, doubleSize, halfSize, quarterSize } from "@/lib/settings";

const Button = ({ label, href }) => (
  <>
    <a href={href} data-label={label}>
      <span className="label">
        {label}
      </span>
    </a>
    <style jsx>{`
      a {
        border: none;
        background: none;
        color: #010101;
        background: #EEE;
        padding: ${quarterSize} ${baseSize};
        border-radius: ${baseSize};
        font-size: ${quarterSize};
        cursor: pointer;
        font-weight: 400;
        outline: none;
        position: relative;
        display: inline-block;
        z-index: 1;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
        overflow: hidden;
      }
      a::before {
        content: "";
        background: #010101;
        display: inline-block;
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        transform: translateZ(0) scaleY(0);
        border-radius: ${baseSize};
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform-origin: center bottom;
        z-index: -1;
      }
      a::after {
        content: attr(data-label);
        transform: translateY(-50%) translateY(50px);
        top: 50%;
        position: absolute;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
        display: inline-block;
        color: #FFF;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 1;
      }
      .label {
        position: relative;
        display: inline-block;
        transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
      }
      a:hover {
        background: transparent;
        transition-delay: 0;
      }
      a:hover > .label {
        transform: translateY(-50px);
        transition-delay: 0;
      }
      a:hover::before {
        transform: translateZ(0) scaleY(1);
      }
      a:hover::after {
        transform: translateY(-50%);
        transition-delay: 0.1s;
      }
    `}</style>
  </>
);

export default Button;
