import Div100vh from "react-div-100vh";
import dynamic from 'next/dynamic'

import { baseSize, doubleSize, halfSize, quarterSize } from "@/lib/settings";
import Button from "@/components/Button";
import Blobs from '@/components/Gallery/Interactive/Blobs'

const Home = () => (
  <div>
    <div className={"introduction"}>
      <h1>ok.systems</h1>
      <div className={"blobs-wrapper"}>
        <Blobs />
      </div>
    </div>

    <style jsx>{`
      .introduction {
        top: ${doubleSize};
        left: ${doubleSize};
        padding-right: ${doubleSize};
        position: absolute;
        z-index: 1;
        max-width: 800px;
      }
      h1 {
        font-size: ${quarterSize};
        margin-bottom: ${baseSize};
        color: #010101;
        z-index: 1;
        font-weight: 400;
        text-transform: uppercase;
      }
      p {
        font-size: ${halfSize};
        line-height: 150%;
        margin-bottom: ${halfSize};
      }
      .button-wrapper {
        margin-top: ${baseSize};
      }
      .blobs-wrapper {
        width: 50vw;
        height: 70vw;
      }
    `}</style>
  </div>
);

export default Home;
