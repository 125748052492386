export const frag = `
precision highp float;
uniform vec3 cameraPos;
uniform float radius;
uniform sampler2D logoTexture;
uniform float bgType;
uniform vec3 bgColor1;
uniform vec3 bgColor2;
uniform float opacity;
uniform float frosted;

varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vPosition;
varying vec3 vLightPosition;


void main() {
  vec3 n = normalize(vNormal);

  vec3 L = normalize( vLightPosition - vPosition );

  vec3 E = normalize( vec3(vPosition.xy, cameraPos.z) - vPosition );

  float fresnelVal = pow(dot(n, E), 100000000.8);

  vec2 texUv = vec2(vUv.x*2.0, vUv.y)*2.0-vec2(0.5, 0.5);


  vec3 bgColor = bgColor1;

  vec3 diffuseColor = mix(bgColor, vec3(0.8), fresnelVal);

  float shininess = 12000000.;
  
  vec3 halfVector = normalize( E + L );
  float specular = dot(n, halfVector);
  specular = max(0.0, specular);
  specular = pow(specular, shininess);

  vec3 specularColor = vec3( 1. );

  gl_FragColor.rgb = mix( diffuseColor, specularColor, specular);
  gl_FragColor.a = opacity;
}
`;

export const vert = `precision highp float;

attribute vec2 uv;
attribute vec3 position;
attribute vec3 normal;

uniform float time;
uniform vec3 pos;
uniform float radius;
uniform vec3 lightPosition;
uniform vec3 cutPoints[47];
uniform vec3 cutNormals[47];
uniform float cutRange[47];
uniform float roundRadiusFactor;
uniform float collisionFactor;
uniform mat4 projectionMatrix;
uniform mat4 modelViewMatrix;

varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vPosition; 
varying vec3 vLightPosition;

const float gap = 1.0;

float mapClamp(float v, float omin, float omax, float tmin, float tmax) {
  float res = tmin + (v - omin) / (omax - omin) * (tmax - tmin);
  return min(tmax, max(tmin, res));
}
void main() {
  vUv = uv;
  vNormal = normal;

  vPosition = pos + normalize(position)*radius;
  vLightPosition = lightPosition;

  float roundRadius = radius/6.0;
  roundRadius = min(roundRadius, 50.0) * roundRadiusFactor;

  for (int i = 0 ; i < 47 ; i++) {
    vec3 origin = cutPoints[i];
    vec3 cutnormal = cutNormals[i];
    float range = cutRange[i];
    
    if (range <= 0.0) continue;

    vec3 unitZ = vec3(0.0, 0.0, 1.0);
    vec3 unitY = cutnormal;
    vec3 unitX = cross(unitZ, unitY);

    float x = dot(vPosition - origin, unitX);
    float y = dot(vPosition - origin, unitY);
    float z = vPosition.z;
    
    vec3 tPosition = vec3(x, y, z);

    float cutY = gap*mapClamp(range, 0.0, 100.0, 0.0, 1.0);
    if (tPosition.y < cutY) {
      tPosition.y = mix(tPosition.y, cutY, collisionFactor);
    }

    if (collisionFactor == 1.0) {
      float d = max(0.0, roundRadius - tPosition.y);
      float offset = roundRadius - sqrt(roundRadius*roundRadius - d*d);
      vec2 tCenter = vec2(0.0, 0.0);
      float distToOrigin = length(tCenter - tPosition.xz);
      float factor = min(1.0, max(0.0, distToOrigin / (range - roundRadius)));
      
      vec2 newXZ = tPosition.xz + normalize(tCenter - tPosition.xz) * offset * factor;
      tPosition.xz = mix(tPosition.xz, newXZ, vec2(smoothstep(roundRadius, roundRadius*2.0, range)));
    }
    
    vPosition = origin + tPosition.x*unitX + tPosition.y*unitY + tPosition.z*unitZ;
  }
  gl_Position = projectionMatrix * modelViewMatrix * vec4(vPosition, 1.0);
}`;
